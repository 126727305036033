export default {
    "vehicles.get": (request, context) => {
        return request;
    },
    "segment.post": (request, context) => {
        return request;
    },
    "plan.post": (request, context) => {
        return request;
    },
    "plan-execute.post": (request, context) => {
        return request;
    },
    "isalive.get": (request, context) => {
        return request;
    },
    "pots.get": (request, context) => {
        return request;
    },
    "stations.get": (request, context) => {
        return request;
    },
    "geocoder.get": (request, context) => {
        return request;
    },
    "reversegeocoder.get": (request, context) => {
        return request;
    },
    "plans-download.post": (request, context) => {
        return request;
    },
    "plan-update.post": (request, context) => {
        return request;
    },
};
